import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueLoading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

createApp(App).mixin({
  methods: {
    getDynamicText(field, section) {
      let content = null;
      const keyword = this.$route.params.keyword;
      if (section !== undefined) {
        content = this.$store.state[section][field][keyword];
        if (content === undefined) {
          content = this.$store.state[section][field].default;
        }
      } else {
        content = this.$store.state[field][keyword];
        if (content === undefined) {
          content = this.$store.state[field].default;
        }
      }

      return content;
    }
  }
}).use(store).use(router).use(VueLoading).mount("#app");
