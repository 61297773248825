<template>
  <div>
    <TopBar></TopBar>
    <div class="above-fold-wrapper">
      <div class="container">
        <Header></Header>
        <div class="filters" ref="filters">
          <div class="row">
            <div class="column refine">
              <div class="title">
                <img
                  alt="Refine Icon"
                  src="@/assets/images/refine-icon.svg"
                  width="20"
                  height="18"
                />
                Tailor your best match:
              </div>
            </div>
            <div class="column dropdowns">
              <div class="dropdown" v-if="this.$store.state.site.fields.coverage_amount">
                <label>Coverage Amount</label>
                <select id="coverage_amount" @change="onChange('coverage_amount', $event.target.value)">
                  <option value="50000" :selected="this.$store.state.coverage_amount === '50000'">$50,000</option>
                  <option value="100000" :selected="this.$store.state.coverage_amount === '100000'">$100,000</option>
                  <option value="150000" :selected="this.$store.state.coverage_amount === '150000'">$150,000</option>
                  <option value="200000" :selected="this.$store.state.coverage_amount === '200000'">$200,000</option>
                  <option value="250000" :selected="this.$store.state.coverage_amount === '250000'">$250,000</option>
                  <option value="500000" :selected="this.$store.state.coverage_amount === '500000'">$500,000</option>
                  <option value="750000" :selected="this.$store.state.coverage_amount === '750000'">$750,000</option>
                  <option value="1000000" :selected="this.$store.state.coverage_amount === '1000000'">$1,000,000
                  </option>
                  <option value="1500000" :selected="this.$store.state.coverage_amount === '1500000'">$1,500,000
                  </option>
                  <option value="2000000" :selected="this.$store.state.coverage_amount === '2000000'">$2,000,000
                  </option>
                  <option value="2500000" :selected="this.$store.state.coverage_amount === '2500000'">$2,500,000
                  </option>
                  <option value="3000000" :selected="this.$store.state.coverage_amount === '3000000'">$3,000,000
                  </option>
                  <option value="3500000" :selected="this.$store.state.coverage_amount === '3500000'">$3,500,000
                  </option>
                  <option value="4000000" :selected="this.$store.state.coverage_amount === '4000000'">$4,000,000
                  </option>
                </select>
              </div>
              <div class="dropdown" v-if="this.$store.state.site.fields.coverage_type">
                <label>Term</label>
                <select id="coverage_type" @change="onChange('coverage_type', $event.target.value)">
                  <option value="Term 1 Year" :selected="this.$store.state.coverage_type === 'Term 1 Year'">1-Year
                  </option>
                  <option value="Term 5 Years" :selected="this.$store.state.coverage_type === 'Term 5 Years'">5-Years
                  </option>
                  <option value="Term 10 Years" :selected="this.$store.state.coverage_type === 'Term 10 Years'">
                    10-Years
                  </option>
                  <option value="Term 15 Years" :selected="this.$store.state.coverage_type === 'Term 15 Years'">
                    15-Years
                  </option>
                  <option value="Term 20 Years" :selected="this.$store.state.coverage_type === 'Term 20 Years'">
                    20-Years
                  </option>
                  <option value="Term 25 Years" :selected="this.$store.state.coverage_type === 'Term 25 Years'">
                    25-Years
                  </option>
                  <option value="Term 30 Years" :selected="this.$store.state.coverage_type === 'Term 30 Years'">
                    30-Years
                  </option>
                  <option value="Not Sure" :selected="this.$store.state.coverage_type === 'Not Sure'">Not Sure</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="below-fold-wrapper" v-if="error">
      <div class="container">
        <div class="top-choice" v-text="error"></div>
      </div>
    </div>
    <Results v-if="ads.length" :ads="ads" :keyword="keyword"></Results>
  </div>
</template>
<script>
import Header from "../components/Header";
import Results from "../components/Results";
import TopBar from "../components/TopBar";

export default {
  name: "Home",
  components: { TopBar, Results, Header },
  data() {
    return {
      ads: [],
      error: null,
      keyword: 'best-companies'
    };
  },
  methods: {
    onChange(field, value) {
      this.updateLocalStore(field, value);
    },
    updateLocalStore(field, value) {
      const obj = {};
      obj[field] = value;
      this.$store.commit("updateStore", obj);
      this.getPlans();
    },
    getPlans() {
      const loader = this.$loading.show({
        canCancel: false
      });
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const data = {};
      if (this.$store.state.site.fields.coverage_amount) {
        data["coverage_amount"] = this.$store.state.coverage_amount;
      }
      if (this.$store.state.site.fields.coverage_type) {
        data["coverage_type"] = this.$store.state.coverage_type;
      }
      const source = this.$route.params.source;
      const product = this.$route.params.product === undefined ? "life" : this.$route.params.product;
      const productSub = product === 'life' ? "Life" : product[0].toUpperCase() + product.slice(1);
      this.keyword = this.$route.params.keyword === undefined ? 'best-companies' : this.$route.params.keyword;
      let placement_id = this.$store.state.placement_id;
      let sub_1 = null;
      if (source !== undefined) {
        if (source === "g") {
          sub_1 = productSub + "/ Google Search / API";
          placement_id = "IarmMqZ14fuMB-FAKdRabxN51wW8Bg";
        }
        if (source === "og") {
          sub_1 = productSub + "/ Organic / API";
          placement_id = "XhFceK7X8TptrlN5jSNXyXzhwuHeWQ";
        }
        if (source === "f") {
          sub_1 = "Facebook"
          placement_id = "AZ9UQRZJOUxZkuPCF8QMgO0HXrdKUA";
        }
        if (source === "1") {
          sub_1 = "Tier 1 API";
          placement_id = "_T9T0ogNS2fHQaePb0MomjlHxeoikA";
        }
        if (source === "2") {
          sub_1 = "Tier 2 API";
          placement_id = "lhK5f5eL2l9gNPVtcMg7kve689zY8A";
        }
        if (source === "3") {
          sub_1 = "Tier 3 API";
          placement_id = "9wdP7cZm1VuuUefUVplusTyUNsc0XQ";
        }
        if (source === "m") {
          sub_1 = "";
          placement_id = "Fha-FcPk290IWSPeIJ4IVTlew5pyaQ";
        }
      }
      if (product === 'car') {
        placement_id = "rHSQSnYVkRxgk22gpWU7kjyJB8_Bkg";
      }
      if (product === 'renter') {
        placement_id = "4CBxDaCTjmsutcchIOyOlFmIkDdOig";
      }
      if (product === 'health') {
        placement_id = "LMC_x1W1hDVYl5vwLme2bwN05C261g";
      }
      if (product === 'commercial-auto') {
        placement_id = "4qt6tXEhC5XiPMXPW8DRvNQD8MgcTA";
      }
      if (product === 'workers-comp') {
        placement_id = "l_W4RlAcNOtEenjE80IAMZWjlznbeg";
      }
      if (product === 'pet') {
        placement_id = "Gn8jQViVbEzJXUa4QpdRi-GSJUohUw";
      }
      const rawJson = {
        placement_id: placement_id,
        data: data
      };
      if (sub_1) {
        rawJson["sub_1"] = sub_1;
      } else {
        rawJson["sub_1"] = "no sub_1";
      }

      rawJson['business'] = 'quoteplicity';
      const raw = JSON.stringify(rawJson);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(
        "https://api.truebluelifeinsurance.com/api/v1/media-alpha/ad",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.ads !== undefined) {
            this.ads = result.ads;
          } else {
            this.error = result.error;
          }
          loader.hide();
        })
        .catch((error) => {
          console.log("error", error);
          this.error = error;
          loader.hide();
        });
    }
  },
  mounted() {
    this.getPlans();
  }
};
</script>
