<template>
  <div class="below-fold-wrapper">
    <div class="container">
      <div class="top-choice">These are your top 2 choices:</div>
      <div class="scroll">
        <div class="results-horizontal">
          <div
            v-for="(ad, index) in ads.slice(0, 2)"
            :key="ad.ad_id"
            class="result-box"
          >
            <div class="row position-relative">
              <div class="number">#{{ index + 1 }}</div>
              <div class="highlight" v-if="!index">Most popular</div>
              <div v-if="keyword === 'best-companies-with-rates'" class="bid" v-text="'$' + ad.bid"></div>
            </div>
            <div class="row">
              <div class="company-logo">
                <a @click="gaEvent(ad.bid)" target="_blank" :href="ad.click_url">
                  <img :src="ad.medium_image_url === undefined ? ad.small_image_url : ad.medium_image_url" alt="" />
                </a>
              </div>
              <div v-if="getScore(ad.carrier) === 'N/R'" class="review">
                <div class="score">
                  <img class="trustpilot-star" alt="TrustPilot Score" src="@/assets/images/trustpilot-star.png" />
                </div>
                <div class="meta">
                  <div class="no-review-label">No TrustPilot<br/>reviews collected</div>
                </div>
              </div>
              <a v-if="getScore(ad.carrier) !== 'N/R'" :href="getRatingByCompany(ad.carrier)?.url" class="review"
                 target="_blank">
                <div class="score" v-text="getScore(ad.carrier)"></div>
                <div class="meta">
                  <img
                    alt="TrustPilot Score"
                    :src="getScore(ad.carrier, true)"
                  />
                  <div class="trust">TrustPilot Score</div>
                </div>
              </a>
              <div class="company-am mobile-only">A.M. Best Rating: {{ getRatingByCompany(ad.carrier)?.am_score }}</div>
            </div>
            <div class="row">
              <div class="company-info">
                <div class="company-name" v-text="ad.carrier"></div>
                <div class="company-tag" v-text="ad.headline"></div>
                <div class="company-am desktop-only">A.M. Best Rating: {{ getRatingByCompany(ad.carrier)?.am_score }}
                </div>
              </div>
              <div class="company-content" v-html="ad.description"></div>
            </div>
            <div class="row">
              <a @click="gaEvent(ad.bid)" target="_blank" :href="ad.click_url" class="hero-btn">View Rates</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="narrow-container" v-if="ads.length > 2">
      <div class="more-options">Explore more options:</div>
      <div class="more-results">
        <div v-for="(ad, index) in ads.slice(2)" :key="index" class="result-rectangle">
          <div class="row position-relative">
            <div class="number">#{{ index + 3 }}</div>
            <div v-if="keyword === 'best-companies-with-rates'" class="bid" v-text="'$' + ad.bid"></div>
          </div>
          <div class="row">
            <div class="column">
              <div class="company-logo">
                <a @click="gaEvent(ad.bid)" target="_blank" :href="ad.click_url">
                  <img :src="ad.medium_image_url === undefined ? ad.small_image_url : ad.medium_image_url" alt="" />
                </a>
              </div>
              <div v-if="getScore(ad.carrier) === 'N/R'" class="review">
                <div class="score">
                  <img class="trustpilot-star" alt="TrustPilot Score" src="@/assets/images/trustpilot-star.png" />
                </div>
                <div class="meta">
                  <div class="no-review-label">No TrustPilot<br/>reviews collected</div>
                </div>
              </div>
              <a
                v-if="getScore(ad.carrier) !== 'N/R'"
                :href="getRatingByCompany(ad.carrier)?.url"
                class="review"
                target="_blank"
              >
                <div class="score" v-text="getScore(ad.carrier)"></div>
                <div class="meta">
                  <img
                    alt="TrustPilot Score"
                    :src="getScore(ad.carrier, true)"
                  />
                  <div class="trust">TrustPilot Score</div>
                </div>
              </a>
              <!--
              <div class="company-am">A.M. Best Rating: {{ getRatingByCompany(ad.carrier)?.am_score }}</div>
              -->
            </div>
            <div class="column">
              <div class="company-info">
                <div class="company-name" v-text="ad.carrier"></div>
                <div class="company-tag" v-text="ad.headline"></div>
              </div>
              <div class="company-content" v-html="ad.description"></div>
            </div>
            <div class="column">
              <a @click="gaEvent(ad.bid)" target="_blank" :href="ad.click_url" class="hero-btn">View Rates</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="hr"></div>
    </div>
    <div class="narrow-container">
      <Faq />
    </div>
  </div>
</template>
<script>
import ratings from "@/assets/json/ratings";
import Faq from "./Faq";

export default {
  name: "Results",
  components: { Faq },
  props: ["ads", "keyword"],
  data() {
    return {
      ratings: ratings
    };
  },
  methods: {
    getRatingByCompany(company) {
      return ratings.filter((rating) => {
        return rating["company"] === company;
      })[0];
    },
    getScore(carrier, img = false) {
      const score = this.getRatingByCompany(carrier)?.score;
      if (score && parseInt(score)) {
        if (img) {
          let star = 0;
          switch (score.toString()) {
            case "5":
              star = 5;
              break;
            case "4.9":
            case "4.8":
            case "4.7":
            case "4.6":
            case "4.5":
            case "4.4":
            case "4.3":
              star = 4.5;
              break;
            case "4.2":
            case "4.1":
            case "4.0":
            case "3.9":
            case "3.8":
              star = 4;
              break;
            case "3.7":
            case "3.6":
            case "3.5":
            case "3.4":
            case "3.3":
              star = 3.5;
              break;
            case "3.2":
            case "3.1":
            case "3.0":
            case "2.9":
            case "2.8":
              star = 3;
              break;
            case "2.7":
            case "2.6":
            case "2.5":
            case "2.4":
            case "2.3":
              star = 2.5;
              break;
            case "2.2":
            case "2.1":
            case "2.0":
            case "1.9":
            case "1.8":
              star = 2;
              break;
            case "1.7":
            case "1.6":
            case "1.5":
            case "1.4":
            case "1.3":
              star = 1.5;
              break;
            default:
              star = 1;
              break;
          }
          return (
            "https://cdn.trustpilot.net/brand-assets/4.1.0/stars/stars-" + star + ".svg"
          );
        }
        return score;
      }
      return "N/R";
    },
    gaEvent(value) {
      if ("ga" in window) {
        ga("create", "UA-912442-38", "auto");
        ga("send", "event", "Media Alpha", "click", "MA API Paid", value.toString());
      }
    }
  }
};
</script>
