<template>
  <router-view />
</template>
<script>
export default {
  beforeCreate() {
    this.$store.commit("initStore");
  },
};
</script>
<style>
@import "./assets/css/styles.css";
</style>
