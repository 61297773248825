<template>
  <div>
    <div class="row">
      <div class="column">
        <div
          v-if="this.$store.state.site.lastUpdated.visible"
          class="last-update"
          v-html="getDynamicText('lastUpdated', 'site') + ' ' + getLastUpdated()"
        ></div>
      </div>
    </div>
    <h1 v-if="this.$store.state.site.title.visible" v-html="getDynamicText('title', 'site')"></h1>
    <h2 v-if="this.$store.state.site.subTitle.visible" v-html="getDynamicText('subTitle', 'site')"></h2>
    <p
      v-if="this.$store.state.site.filtersBlurb.visible"
      v-html="getDynamicText('filtersBlurb', 'site')"
    ></p>
  </div>
</template>
<script>
export default {
  methods: {
    getLastUpdated() {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];
      const d = new Date();
      return monthNames[d.getMonth()] + " " + d.getFullYear();
    }
  }
};
</script>
