import { createStore } from "vuex";

export default createStore({
  state: {
    placement_id: "Cw6-ycCscBdvKDHgCtMIRHrY2mo79g",
    coverage_amount: "250000",
    coverage_type: "Term 10 Years",
    zip: null,
    site: {
      lastUpdated: {
        visible: true,
        default: "Last Updated"
      },
      title: {
        visible: true,
        default: "Best <span class=\"highlight-color\">Life Insurance Companies</span> of 2022",
        "best-companies": "Best <span class=\"highlight-color\">Life Insurance Companies</span> of 2022",
        "term-life-insurance": "Best <span class=\"highlight-color\">Term Life Insurance Companies</span> of 2022",
        "affordable-life-insurance": "<span class=\"highlight-color\">Most Affordable</span> Life Insurance Companies of 2022",
        "instant-life-insurance": "Best <span class=\"highlight-color\">Instant Life Insurance Companies</span> of 2022",
        "no-medical-life-insurance": "Best <span class=\"highlight-color\">No Medical Life Insurance Companies</span> of 2022",
        "life-insurance-rates-online": "Compare Life Insurance Rates Online"
      },
      subTitle: {
        visible: true,
        default: "Find your ideal life insurance provider in seconds.",
        "life-insurance-rates-online": "Your online quote is just a click away."
      },
      filtersBlurb: {
        visible: true,
        default:
          "We constantly update our list of best companies based on the latest pricing, performance and reviews."
      },
      advertiserDisclosure: {
        visible: true,
        default:
          "Insurist is a free online resource that strives to offer helpful content and comparison features to our visitors. We accept advertising compensation from companies that appear on the site, which impacts which brands (and/or their products) are presented. We do not feature all providers on the market. The information, including pricing, which appears on this site is subject to change at any time."
      },
      fields: {
        coverage_amount: true,
        coverage_type: true
      }
    }
  },
  mutations: {
    initStore(state) {
      for (const [key, value] of Object.entries(state)) {
        if (key !== "site") {
          const itemValue = localStorage.getItem(key);
          if (itemValue) {
            state[key] = localStorage.getItem(key);
          } else {
            localStorage.setItem(key, value);
          }
        }
      }
    },
    updateStore(state, data) {
      for (const [key, value] of Object.entries(data)) {
        state[key] = value;
        localStorage.setItem(key, state[key]);
      }
    },
    clearStore(state, reset) {
      if (reset) {
        localStorage.clear();
      }
    }
  },
  actions: {},
  modules: {}
});
