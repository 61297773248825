<template>
  <div class="content">
  <h2>Why Should You Buy Life Insurance?</h2>
  <p>Here are some basic reasons to consider:</p>
  <ul>
  <li><strong>Your loved ones have security.</strong> No matter what happens, your family has financial peace of mind when you have life insurance.</li>
  <li><strong>You reduce stress and anxiety.</strong> Obviously, this is something you’ve been considering for a while now. Many of our customers tell us that a weight has been lifted once they have life insurance.</li>
  <li><strong>You avoid probate.</strong> Your beneficiaries receive death benefits within weeks at the most, and often within days.</li>
  <li><strong>You avoid taxes.</strong> A relatively small investment can pay off immensely for your beneficiaries. Life insurance is one of the very best ways to transfer wealth</li>
  <li><strong>You lock in guaranteed coverage.</strong> Once the application is approved, your premium and death benefit stay the same. That means you don’t have to do anything else until your policy’s end date.</li>
  <li><strong>You have benefits while you’re alive.</strong> If something happens and you’re told you have a year or less left to live, various types of living benefits can allow you access a portion of your payout to spend as you wish.</li>
  </ul>
    <hr>
    <h2>How Does Term Life Insurance Work?</h2>
    <p>
      Term life insurance is pretty simple. You choose how much coverage you
      want, and how long you want the coverage for (known as the term). If
      the insured passes away during the chosen term, the beneficiaries will
      receive the death benefit.
    </p>
    <p>
      During the term, the cost (known as the premium) will never increase
      and the term will never decrease. Furthermore, the policy will never
      be canceled, even if your health changes or you develop serious health
      conditions. The only time the insurer will cancel your policy is if
      you miss payments.
    </p>
    <hr>
    <h2>Choosing The Right Coverage</h2>
    <p><strong>Simple calculation: annual income x 15</strong></p>
    <p>
      Coverage wise, a simple calculation is to multiply your annual income
      times 15. For example, if you make $50,000 a year, $750,000 of
      coverage is a good rule of thumb.
    </p>
    <p><strong>Above all: choose a comfortable budget</strong></p>
    <p>
      But, you shouldn’t have to struggle to make life insurance policy
      payments. We firmly believe that a $50,000 policy you can easily
      afford is better than a $750,000 policy that may lapse because you
      missed payments. So, be sure to compare rates for multiple coverage
      amounts. Make sure you can afford the policy you get.
    </p>
    <hr>
    <h2>Choosing The Policy Term</h2>
    <p>
      The policy term is the duration of how long the contract between you
      and the insurer will last. Most insurance companies offer 5, 10, 15,
      20, 25, and 30-year policies. Some even offer 40-year policies.
    </p>
    <p>
      The maximum term period that will be available to you depends on your
      age. Most likely, you won’t be able to get coverage beyond age 90 with
      a term policy.
    </p>
    <p>
      Keep in mind that you can always cancel your term life insurance
      policy if you no longer need it.
    </p>
    <p>
      We believe that it’s better to opt for a 20-year term to be on the
      safe side. While it costs slightly more than a 10-year term policy,
      you will save a lot of money compared to getting a 10-year term, and
      then getting another 10-year term policy once the first policy ends.
    </p>
    <hr>
    <h2>How Much Does Term Life Insurance Cost?</h2>
    <p>Probably less than you think. Term life insurance is one of the most affordable types of life insurance. In fact, according to our recent study, over half of those asked think the cost of a term life insurance policy is 3x or more than its actual cost.</p>
    <h3>Average term life insurance rates by age</h3>
    <p>These rates are calculated for a 20-year term policy.</p>

<table>
    <tr>
        <th>Age & Gender</th>
        <th>$50,000</th>
        <th>$250,000</th>
        <th>$500,000</th>
        <th>$1,000,000</th>
    </tr>
    <tr>
        <td>25 – Male</td>
        <td>$8.61</td>
        <td>$12.35</td>
        <td>$19.11</td>
        <td>$31.16</td>
    </tr>
    <tr>
        <td>25 – Female</td>
        <td>$7.83</td>
        <td>$10.89</td>
        <td>$15.72</td>
        <td>$23.47</td>
    </tr>
    <tr>
        <td>35 – Male</td>
        <td>$9.48</td>
        <td>$13.38</td>
        <td>$21.28</td>
        <td>$35.06</td>
    </tr>
    <tr>
        <td>35 – Female</td>
        <td>$8.66</td>
        <td>$12.12</td>
        <td>$17.87</td>
        <td>$29.91</td>
    </tr>
    <tr>
        <td>45 – Male</td>
        <td>$15.27</td>
        <td>$26.31</td>
        <td>$46.27</td>
        <td>$88.85</td>
    </tr>
    <tr>
        <td>45 – Female</td>
        <td>$12.83</td>
        <td>$21.22</td>
        <td>$37.20</td>
        <td>$69.10</td>
    </tr>
    <tr>
        <td>55 – Male</td>
        <td>$31.97</td>
        <td>$64.81</td>
        <td>$119.13</td>
        <td>$227.85</td>
    </tr>
    <tr>
        <td>55 – Female</td>
        <td>$24.71</td>
        <td>$48.87</td>
        <td>$88.73</td>
        <td>$166.17</td>
    </tr>
    <tr>
        <td>65 – Male</td>
        <td>$81.61</td>
        <td>$201.35</td>
        <td>$387.35</td>
        <td>$748.10</td>
    </tr>
    <tr>
        <td>65 – Female</td>
        <td>$63.12</td>
        <td>$142.47</td>
        <td>$264.22</td>
        <td>$508.99</td>
    </tr>
</table>
<p>*The prices above are sample prices calculated for non-smokers based in Arizona in excellent health.</p>
<div class="footer">
<a href="https://www.insurist.com/privacy-policy/" target="_blank">Privacy Policy</a>
</div>
  </div>
</template>
<script>
export default {}
</script>
