<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="logo">
          <img alt="Logo" src="https://www.quoteplicity.com/quoteplicity.svg" />
        </div>
        <div class="menu">
          <div class="disclosure">
            <div class="link">Advertiser disclosure</div>
            <div
              v-if="this.$store.state.site.advertiserDisclosure.visible"
              class="content"
              v-html="getDynamicText('advertiserDisclosure', 'site')"
            ></div>
          </div>
        </div>
        <div class="mobile-menu">
          <img
            alt="Mobile Menu Icon"
            src="@/assets/images/mobile-menu-icon.svg"
            width="25"
            height="20"
          />
        </div>
      </div>
    </div>
  </header>
</template>
